export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [7],
		"/(app)/admin": [8,[2,4],[3]],
		"/(app)/admin/community": [9,[2,4],[3]],
		"/(app)/admin/workspace": [10,[2,4],[3]],
		"/auth": [29],
		"/auth/forgot-password": [30],
		"/auth/lti-add-unit": [31],
		"/auth/signup": [32],
		"/auth/update-password": [33],
		"/dev": [34],
		"/dev/ManageMembers": [35],
		"/dev/gifs": [36],
		"/dev/kit-server": [37],
		"/dev/projects": [38],
		"/logout": [~39],
		"/(app)/my-uni": [11,[2],[3]],
		"/(app)/my-uni/[unit]": [12,[2],[3]],
		"/(app)/my-uni/[unit]/details": [14,[2],[3]],
		"/(app)/my-uni/[unit]/dev": [15,[2],[3]],
		"/(app)/my-uni/[unit]/subgroup/[subgroupId]": [16,[2],[3]],
		"/(app)/my-uni/[unit]/subgroup/[subgroupId]/details": [18,[2],[3]],
		"/(app)/my-uni/[unit]/subgroup/[subgroupId]/[post]": [17,[2],[3]],
		"/(app)/my-uni/[unit]/[post]": [13,[2],[3,5]],
		"/(app)/network": [19,[2],[3]],
		"/(app)/network/dms": [20,[2,6],[3]],
		"/(app)/network/dms/group/[subgroupId]": [23,[2,6],[3]],
		"/(app)/network/dms/[friendId]": [21,[2,6],[3]],
		"/(app)/network/dms/[friendId]/details": [22,[2,6],[3]],
		"/(app)/notifications": [24,[2],[3]],
		"/(app)/profile/me": [26,[2],[3]],
		"/(app)/profile/me/edit": [27,[2],[3]],
		"/(app)/profile/settings": [28,[2],[3]],
		"/(app)/profile/[profileId]": [25,[2],[3]],
		"/resend": [~40]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';